import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"

const BeratungPage = () => (
  <Layout>
    <SEO title="Beratung" />

    <h1 id="ueberschrift">Informationen zum Beratungsangebot</h1>
    <Link to="/fdml_6a_beratung-klartext#ueberschrift" title="Detaillierte Informationen zum Beratungsangebot" style={{fontSize: "1.5em"}}>zum Klartext</Link>

    <p className="poem" style={{marginTop: "0.5em"}}>
      Ein Weg so grad und wunderschön…<br />
      Auf dem möchte ein jeder geh’n!<br />
      Ein Traum, der so unmöglich scheint,<br />
      Dass man die Existenz verneint?!<br />
      Der eigne Weg, steinig und krumm,<br />
      Scheint Dir zu sagen „Du bist dumm.“?!<br />
      Ein jeder Stein wiegt Zentnerschwer…<br />
      Wenn doch nur Hilf‘ zur Stelle wär?!<br />
      Mit Fantasie, die Dich erreicht,<br />
      So dass ein reißend Strom wird seicht;<br />
      Der mit durch dunkle Wälder zieht<br />
      Und hilft dass jedes Leid entflieht;<br />
      Der Steine bricht und nimmt die Stücke<br />
      Und hilft Dir bauen eine Brücke;<br />
      Der hilft Irrlichter zu erkennen<br />
      Und all das Übel beim Namen zu nennen;<br />
      Ein Gefährte, der mit Dir im Sumpf<br />
      Den Weg sucht und des Lebens Trumpf;<br />
      Der hilft mit Wesen zu interagieren<br />
      Egal ob mit zwei Beinen oder Vieren,<br />
      Ob mit Federn oder Schuppen gar,<br />
      Ob „eklig“ oder „wunderbar“…
    </p>

    <p className="poem">
      Wenn Du keinen willst brüskieren,<br />
      Und willst auch keine Zeit verlieren,<br />
      Dann ruf mit einem „Codewort“ an:<br />
      Im Geheimen wir uns treffen dann.
    </p>
  </Layout>
              )

              export default BeratungPage
